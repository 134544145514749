export interface CaseStudyItem {
  Category1: string;
  Category2: string;
  Bullet1: string;
  Bullet2: string;
  Bullet3: string;
  Bullet4: any;
  Bullet5: any;
  TaxAssessedValue: number;
  ReductionInAssessedValue: number;
  NewTaxAssessedValue: number;
  AnnualPropertyTaxSavings: number;
  PercentReductionInTaxAssessedValue: number;
  ReductionInAssessedValue1: number;
  EffectiveTaxRate: number;
  ImageName: string;
}

export const getResidentialCategories = () => {
  return Array.from(new Set(caseStudyItems.filter((value) => value.Category1 === 'Residential').map((item) => item.Category2)));
};

export const getCommercialCategories = () => {
  return Array.from(new Set(caseStudyItems.filter((value) => value.Category1 === 'Commercial').map((item) => item.Category2)));
};

export const caseStudyItems: CaseStudyItem[] = [
  {
    Category1: 'Residential',
    Category2: '$1MM-$5MM',
    Bullet1: 'Built in 2014',
    Bullet2: '0.23 Acre Parcel',
    Bullet3: '6,812 SqFt of Improved Space',
    Bullet4: null,
    Bullet5: null,
    TaxAssessedValue: 3500380,
    ReductionInAssessedValue: 500380,
    NewTaxAssessedValue: 3000000,
    AnnualPropertyTaxSavings: 10408,
    PercentReductionInTaxAssessedValue: 0.14,
    ReductionInAssessedValue1: 500380,
    EffectiveTaxRate: 0.0208,
    ImageName: 'shutterstock_48791203',
  },
  {
    Category1: 'Residential',
    Category2: '$700K-$1MM',
    Bullet1: 'Built in 1984',
    Bullet2: '0.78 Acre Parcel',
    Bullet3: '3,326 SqFt of Improved Space',
    Bullet4: null,
    Bullet5: null,
    TaxAssessedValue: 873640,
    ReductionInAssessedValue: 124640,
    NewTaxAssessedValue: 749000,
    AnnualPropertyTaxSavings: 3004,
    PercentReductionInTaxAssessedValue: 0.14,
    ReductionInAssessedValue1: 124640,
    EffectiveTaxRate: 0.0241,
    ImageName: 'shutterstock_217424809',
  },
  // {
  //   Category1: 'Residential',
  //   Category2: '$500K-$700K',
  //   Bullet1: 'Built in 2008',
  //   Bullet2: '0.13 Acre Parcel',
  //   Bullet3: '3,745 SqFt of Improved Space',
  //   Bullet4: null,
  //   Bullet5: null,
  //   TaxAssessedValue: 508669,
  //   ReductionInAssessedValue: 83669,
  //   NewTaxAssessedValue: 425000,
  //   AnnualPropertyTaxSavings: 1899,
  //   PercentReductionInTaxAssessedValue: 0.16,
  //   ReductionInAssessedValue1: 83669,
  //   EffectiveTaxRate: 0.0227,
  //   ImageName: null,
  // },
  {
    Category1: 'Residential',
    Category2: '$400K-$500K',
    Bullet1: 'Built in 2006',
    Bullet2: '0.25 Acre Parcel',
    Bullet3: '3,122 SqFt of Improved Space',
    Bullet4: null,
    Bullet5: null,
    TaxAssessedValue: 492290,
    ReductionInAssessedValue: 108290,
    NewTaxAssessedValue: 384000,
    AnnualPropertyTaxSavings: 2545,
    PercentReductionInTaxAssessedValue: 0.22,
    ReductionInAssessedValue1: 108290,
    EffectiveTaxRate: 0.0235,
    ImageName: 'shutterstock_211775290',
  },
  // {
  //   Category1: 'Residential',
  //   Category2: '$300K-$400K',
  //   Bullet1: 'Built in 2002',
  //   Bullet2: '0.20 Acre Parcel',
  //   Bullet3: '3,082 SqFt of Improved Space',
  //   Bullet4: null,
  //   Bullet5: null,
  //   TaxAssessedValue: 340726,
  //   ReductionInAssessedValue: 57726,
  //   NewTaxAssessedValue: 283000,
  //   AnnualPropertyTaxSavings: 1235,
  //   PercentReductionInTaxAssessedValue: 0.17,
  //   ReductionInAssessedValue1: 57726,
  //   EffectiveTaxRate: 0.0214,
  //   ImageName: null,
  // },
  {
    Category1: 'Residential',
    Category2: '$200K-300K',
    Bullet1: 'Built in 2018',
    Bullet2: '2,061 SqFt',
    Bullet3: null,
    Bullet4: null,
    Bullet5: null,
    TaxAssessedValue: 261524,
    ReductionInAssessedValue: 68524,
    NewTaxAssessedValue: 193000,
    AnnualPropertyTaxSavings: 1953,
    PercentReductionInTaxAssessedValue: 0.26,
    ReductionInAssessedValue1: 68524,
    EffectiveTaxRate: 0.0285,
    ImageName: 'shutterstock_399557398',
  },
  {
    Category1: 'Residential',
    Category2: '$200K-300K',
    Bullet1: 'Built in 2011',
    Bullet2: '3,358 SqFt',
    Bullet3: null,
    Bullet4: null,
    Bullet5: null,
    TaxAssessedValue: 276426,
    ReductionInAssessedValue: 63722,
    NewTaxAssessedValue: 212704,
    AnnualPropertyTaxSavings: 1740,
    PercentReductionInTaxAssessedValue: 0.23,
    ReductionInAssessedValue1: 63722,
    EffectiveTaxRate: 0.0273,
    ImageName: 'shutterstock_524082304',
  },
  {
    Category1: 'Residential',
    Category2: '$200K-300K',
    Bullet1: 'Built in 2009',
    Bullet2: '2,615 SqFt',
    Bullet3: null,
    Bullet4: null,
    Bullet5: null,
    TaxAssessedValue: 263894,
    ReductionInAssessedValue: 55894,
    NewTaxAssessedValue: 208000,
    AnnualPropertyTaxSavings: 1704,
    PercentReductionInTaxAssessedValue: 0.21,
    ReductionInAssessedValue1: 55894,
    EffectiveTaxRate: 0.0305,
    ImageName: 'shutterstock_524082304',
  },
  {
    Category1: 'Commercial',
    Category2: 'Medical',
    Bullet1: 'Built in 2015',
    Bullet2: '2.15 Acre Parcel',
    Bullet3: '22,442 SqFt of Improved Space',
    Bullet4: null,
    Bullet5: null,
    TaxAssessedValue: 4478415,
    ReductionInAssessedValue: 578415,
    NewTaxAssessedValue: 3900000,
    AnnualPropertyTaxSavings: 15184,
    PercentReductionInTaxAssessedValue: 0.1292,
    ReductionInAssessedValue1: 578415,
    EffectiveTaxRate: 0.0263,
    ImageName: 'shutterstock_190162013',
  },
  {
    Category1: 'Commercial',
    Category2: 'Medical',
    Bullet1: 'Built in 2014',
    Bullet2: '1.99 Acre Parcel',
    Bullet3: '132,684 SqFt of Improved Space',
    Bullet4: null,
    Bullet5: null,
    TaxAssessedValue: 13594550,
    ReductionInAssessedValue: 1594550,
    NewTaxAssessedValue: 12000000,
    AnnualPropertyTaxSavings: 48315,
    PercentReductionInTaxAssessedValue: 0.1173,
    ReductionInAssessedValue1: 1594550,
    EffectiveTaxRate: 0.0303,
    ImageName: 'shutterstock_63112756',
  },
  {
    Category1: 'Commercial',
    Category2: 'Office',
    Bullet1: 'Built in 1971',
    Bullet2: '2.25 Acre Parcel',
    Bullet3: '34,000 SqFt of Improved Space',
    Bullet4: null,
    Bullet5: null,
    TaxAssessedValue: 848245,
    ReductionInAssessedValue: 141383,
    NewTaxAssessedValue: 706862,
    AnnualPropertyTaxSavings: 3922,
    PercentReductionInTaxAssessedValue: 0.1667,
    ReductionInAssessedValue1: 141383,
    EffectiveTaxRate: 0.0277,
    ImageName: 'shutterstock_137684501',
  },
  {
    Category1: 'Commercial',
    Category2: 'Office',
    Bullet1: 'Built in 1981',
    Bullet2: '3.40 Acre Parcel',
    Bullet3: '104,653 SqFt of Improved Space',
    Bullet4: null,
    Bullet5: null,
    TaxAssessedValue: 4122801,
    ReductionInAssessedValue: 479134,
    NewTaxAssessedValue: 3643667,
    AnnualPropertyTaxSavings: 12122,
    PercentReductionInTaxAssessedValue: 0.1162,
    ReductionInAssessedValue1: 479134,
    EffectiveTaxRate: 0.0253,
    ImageName: 'shutterstock_211959382',
  },
  {
    Category1: 'Commercial',
    Category2: 'Multi Family',
    Bullet1: 'Mid-Rise',
    Bullet2: '151 Units',
    Bullet3: 'Built in 2017',
    Bullet4: '3.18 Acre Parcel',
    Bullet5: '77,396 SqFt of Improved Space',
    TaxAssessedValue: 10876560,
    ReductionInAssessedValue: 3951560,
    NewTaxAssessedValue: 6925000,
    AnnualPropertyTaxSavings: 112298,
    PercentReductionInTaxAssessedValue: 0.3633,
    ReductionInAssessedValue1: 3951560,
    EffectiveTaxRate: 0.0284,
    ImageName: 'shutterstock_645323449',
  },
  // {
  //   Category1: 'Commercial',
  //   Category2: 'Multi Family',
  //   Bullet1: 'Low-Rise',
  //   Bullet2: '12 Units',
  //   Bullet3: 'Built in 1937',
  //   Bullet4: '9,150 of Improved Space',
  //   Bullet5: null,
  //   TaxAssessedValue: 416000,
  //   ReductionInAssessedValue: 145000,
  //   NewTaxAssessedValue: 271000,
  //   AnnualPropertyTaxSavings: 3943,
  //   PercentReductionInTaxAssessedValue: 0.3486,
  //   ReductionInAssessedValue1: 145000,
  //   EffectiveTaxRate: 0.0272,
  //   ImageName: null,
  // },
  // {
  //   Category1: 'Commercial',
  //   Category2: 'Multi Family',
  //   Bullet1: 'Low-Rise',
  //   Bullet2: '264 Units',
  //   Bullet3: 'Built in 1973',
  //   Bullet4: '10.54 Acre Parcel',
  //   Bullet5: '250,176 SqFt of Improved Space',
  //   TaxAssessedValue: 17087790,
  //   ReductionInAssessedValue: 3611470,
  //   NewTaxAssessedValue: 13476320,
  //   AnnualPropertyTaxSavings: 113400,
  //   PercentReductionInTaxAssessedValue: 0.2113,
  //   ReductionInAssessedValue1: 3611470,
  //   EffectiveTaxRate: 0.0314,
  //   ImageName: null,
  // },
  {
    Category1: 'Commercial',
    Category2: 'Retail',
    Bullet1: 'Built in 1987',
    Bullet2: '0.54 Acre Parcel',
    Bullet3: '3,360SqFt of Improved Space',
    Bullet4: null,
    Bullet5: null,
    TaxAssessedValue: 1031486,
    ReductionInAssessedValue: 538486,
    NewTaxAssessedValue: 493000,
    AnnualPropertyTaxSavings: 15436,
    PercentReductionInTaxAssessedValue: 0.1162,
    ReductionInAssessedValue1: 538486,
    EffectiveTaxRate: 0.0287,
    ImageName: 'shutterstock_144849883',
  },
  {
    Category1: 'Commercial',
    Category2: 'Retail',
    Bullet1: 'Built in 1985',
    Bullet2: '2.25 Acre Parcel',
    Bullet3: '34,100 SqFt of Improved Space',
    Bullet4: null,
    Bullet5: null,
    TaxAssessedValue: 1504182,
    ReductionInAssessedValue: 710470,
    NewTaxAssessedValue: 793712,
    AnnualPropertyTaxSavings: 18756,
    PercentReductionInTaxAssessedValue: 0.4723,
    ReductionInAssessedValue1: 710470,
    EffectiveTaxRate: 0.0264,
    ImageName: 'shutterstock_120505000',
  },
  // {
  //   Category1: 'Commercial',
  //   Category2: 'Warehouse & Self Storage',
  //   Bullet1: 'Logistics Hub',
  //   Bullet2: '12 Units',
  //   Bullet3: 'Built in 2012',
  //   Bullet4: '240,081 SqFt of Improved Space',
  //   Bullet5: '12.15 Acre Parcel',
  //   TaxAssessedValue: 16146518,
  //   ReductionInAssessedValue: 2662005,
  //   NewTaxAssessedValue: 13484513,
  //   AnnualPropertyTaxSavings: 63416,
  //   PercentReductionInTaxAssessedValue: 0.1649,
  //   ReductionInAssessedValue1: 2662005,
  //   EffectiveTaxRate: 0.0238,
  //   ImageName: null,
  // },
  {
    Category1: 'Commercial',
    Category2: 'Warehouse & Self Storage',
    Bullet1: 'Flex Warehouse',
    Bullet2: 'Built in 2007',
    Bullet3: '1.80 Acre Parcel',
    Bullet4: '27,434 SqFt of Improved Space',
    Bullet5: null,
    TaxAssessedValue: 4564560,
    ReductionInAssessedValue: 668400,
    NewTaxAssessedValue: 3896160,
    AnnualPropertyTaxSavings: 14705,
    PercentReductionInTaxAssessedValue: 0.1464,
    ReductionInAssessedValue1: 668400,
    EffectiveTaxRate: 0.022,
    ImageName: 'shutterstock_766433938',
  },
  {
    Category1: 'Commercial',
    Category2: 'Special Use: Recreation Center',
    Bullet1: 'Sports & Events',
    Bullet2: 'Built in 2018',
    Bullet3: '8.06 Acre Parcel',
    Bullet4: '59,520 SqFt of improved Space',
    Bullet5: null,
    TaxAssessedValue: 7997660,
    ReductionInAssessedValue: 5639820,
    NewTaxAssessedValue: 2357840,
    AnnualPropertyTaxSavings: 173142,
    PercentReductionInTaxAssessedValue: 0.7052,
    ReductionInAssessedValue1: 5639820,
    EffectiveTaxRate: 0.0307,
    ImageName: 'shutterstock_16734280',
  },
  {
    Category1: 'Commercial',
    Category2: 'Special Use: Data Center',
    Bullet1: 'Built in 1981',
    Bullet2: '240081',
    Bullet3: null,
    Bullet4: null,
    Bullet5: null,
    TaxAssessedValue: 2077110,
    ReductionInAssessedValue: 284070,
    NewTaxAssessedValue: 1796640,
    AnnualPropertyTaxSavings: 7382,
    PercentReductionInTaxAssessedValue: 0.135,
    ReductionInAssessedValue1: 280470,
    EffectiveTaxRate: 0.0263,
    ImageName: 'shutterstock_1910217',
  },
];
