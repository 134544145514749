import * as React from 'react';

import { CaseStudyItem, caseStudyItems, getCommercialCategories, getResidentialCategories } from '../data/case-studies';

import { Footer, NavMenu, PageTitle } from '../components/shared';

import { Badge, Button, Card, Col, Container, Dropdown, DropdownButton, Image, Modal, Row, Stack } from 'react-bootstrap';
import { faPiggyBank } from '@fortawesome/pro-duotone-svg-icons';
import { faCheck, faFilter, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CaseStudiesPage = () => {
  const [selectedItem, setSelectedItem] = React.useState<CaseStudyItem>(null);
  const [resFilters, setResFilters] = React.useState<string[]>([]);
  const [comFilters, setComFilters] = React.useState<string[]>([]);

  React.useEffect(() => {
    setResFilters(getResidentialCategories());
    setComFilters(getCommercialCategories());
  }, []);

  function currencyFormatInt(num: number | null): string {
    if (num === null || num === 0 || num === undefined) return '$0';
    return '$' + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  const handleResFilterSelect = (value: string) => {
    if (resFilters.includes(value)) {
      setResFilters(resFilters.filter((item) => item !== value));
    } else {
      setResFilters((prevState) => [value, ...prevState]);
    }
  };

  const handleResClearAll = () => {
    setResFilters([]);
  };

  const handleResSelectAll = () => {
    setResFilters(getResidentialCategories);
  };

  const handleComFilterSelect = (value: string) => {
    if (comFilters.includes(value)) {
      setComFilters(comFilters.filter((item) => item !== value));
    } else {
      setComFilters((prevState) => [value, ...prevState]);
    }
  };

  const handleComClearAll = () => {
    setComFilters([]);
  };

  const handleComSelectAll = () => {
    setComFilters(getCommercialCategories);
  };

  return (
    <>
      <PageTitle />
      <NavMenu solidColor />
      <Container className="full-page">
        <Stack>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 24, justifyContent: 'center' }}>
            <FontAwesomeIcon
              icon={faPiggyBank}
              size="4x"
              fixedWidth
              color="#fc6a01"
              style={{ '--fa-primary-color': '#082556', '--fa-secondary-opacity': '1' }}
            />
            <h1>Case Studies</h1>
          </div>
          <Card className="mb-3">
            <Card.Body className="d-flex gap-2">
              <DropdownButton
                variant="secondary"
                autoClose="outside"
                title={
                  <>
                    <FontAwesomeIcon icon={faFilter} fixedWidth />
                    Residential
                  </>
                }
              >
                <Dropdown.Item onClick={() => handleResClearAll()}>
                  <FontAwesomeIcon icon={faTimes} fixedWidth /> Clear All
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleResSelectAll()}>
                  <FontAwesomeIcon icon={faCheck} fixedWidth /> Select All
                </Dropdown.Item>
                <Dropdown.Divider />
                {getResidentialCategories().map((item, index) => (
                  <Dropdown.Item key={index} onClick={() => handleResFilterSelect(item)}>
                    <FontAwesomeIcon icon={faCheck} fixedWidth style={{ visibility: resFilters.includes(item) ? 'visible' : 'hidden' }} /> {item}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
              <DropdownButton
                variant="secondary"
                autoClose="outside"
                title={
                  <>
                    <FontAwesomeIcon icon={faFilter} fixedWidth />
                    Commercial
                  </>
                }
              >
                <Dropdown.Item onClick={() => handleComClearAll()}>
                  <FontAwesomeIcon icon={faTimes} fixedWidth /> Clear All
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleComSelectAll()}>
                  <FontAwesomeIcon icon={faCheck} fixedWidth /> Select All
                </Dropdown.Item>
                <Dropdown.Divider />
                {getCommercialCategories().map((item, index) => (
                  <Dropdown.Item key={index} onClick={() => handleComFilterSelect(item)}>
                    <FontAwesomeIcon icon={faCheck} fixedWidth style={{ visibility: comFilters.includes(item) ? 'visible' : 'hidden' }} /> {item}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </Card.Body>
          </Card>
          <Row>
            {caseStudyItems
              .filter(
                (item) =>
                  (item.Category1 === 'Residential' && resFilters.includes(item.Category2)) ||
                  (item.Category1 === 'Commercial' && comFilters.includes(item.Category2))
              )
              .map((item, index) => {
                return (
                  <Col md={4}>
                    <Card style={{ marginBottom: '1rem' }}>
                      <Card.Img
                        variant="top"
                        style={{ maxHeight: 280 }}
                        src={item.ImageName ? `/case-studies/${item.ImageName}.jpg` : 'https://placehold.co/600x400?text=Placeholder'}
                      />
                      <Card.Body>
                        <Card.Title>
                          {item.Category1} - {item.Category2}
                        </Card.Title>
                        <Card.Text>
                          {item.Bullet1 && (
                            <Badge pill className="bg-dark-blue">
                              {item.Bullet1}
                            </Badge>
                          )}
                          {item.Bullet2 && (
                            <Badge pill className="bg-dark-blue">
                              {item.Bullet2}
                            </Badge>
                          )}
                          {item.Bullet3 && (
                            <Badge pill className="bg-dark-blue">
                              {item.Bullet3}
                            </Badge>
                          )}
                          {item.Bullet4 && (
                            <Badge pill className="bg-dark-blue">
                              {item.Bullet4}
                            </Badge>
                          )}
                          {item.Bullet5 && (
                            <Badge pill className="bg-dark-blue">
                              {item.Bullet5}
                            </Badge>
                          )}
                        </Card.Text>
                      </Card.Body>
                      <Card.Footer>
                        <Button variant="primary" onClick={() => setSelectedItem(item)}>
                          More Info
                        </Button>
                      </Card.Footer>
                    </Card>
                  </Col>
                );
              })}
          </Row>
        </Stack>
      </Container>
      <Footer />
      {selectedItem !== null && (
        <Modal size="lg" centered show={selectedItem !== null} onHide={() => setSelectedItem(null)}>
          <Modal.Header closeButton>
            <Modal.Title>
              {selectedItem.Category1} - {selectedItem.Category2}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Image
              rounded
              fluid
              className="mb-3"
              src={selectedItem.ImageName ? `/case-studies/${selectedItem.ImageName}.jpg` : 'https://placehold.co/600x400?text=Placeholder'}
            />
            <Row>
              <Col md={6}>
                <div className="mb-1">
                  <div className="prop-value">{currencyFormatInt(selectedItem.TaxAssessedValue)}</div>
                  Tax Assessed Value
                </div>
                <div className="mb-1">
                  <div className="prop-value">({currencyFormatInt(selectedItem.ReductionInAssessedValue)})</div>
                  Reduction assessed Value
                </div>
                <div className="mb-1" style={{ borderTop: '2px solid #fd6806' }}></div>
                <div className="mb-1">
                  <div className="prop-value">{currencyFormatInt(selectedItem.NewTaxAssessedValue)}</div>
                  New Tax Assessed Value
                </div>
                <div className="fs-5 mb-2 mt-4 fw-bold">Annual Property Tax Savings {currencyFormatInt(selectedItem.AnnualPropertyTaxSavings)}</div>
                <div className="mb-1">{(selectedItem.PercentReductionInTaxAssessedValue * 100).toFixed(0)}% reduction in tax assessed value</div>
                <div className="mb-1">{currencyFormatInt(selectedItem.ReductionInAssessedValue1)} reduction in assessment</div>
                <div className="mb-1">at {(selectedItem.EffectiveTaxRate * 100).toFixed(2)}% effective tax rate</div>
              </Col>
              <Col md={{ span: 4, offset: 2 }}>
                {selectedItem.Bullet1 && (
                  <Badge pill className="bg-dark-blue">
                    {selectedItem.Bullet1}
                  </Badge>
                )}
                {selectedItem.Bullet2 && (
                  <Badge pill className="bg-dark-blue">
                    {selectedItem.Bullet2}
                  </Badge>
                )}
                {selectedItem.Bullet3 && (
                  <Badge pill className="bg-dark-blue">
                    {selectedItem.Bullet3}
                  </Badge>
                )}
                {selectedItem.Bullet4 && (
                  <Badge pill className="bg-dark-blue">
                    {selectedItem.Bullet4}
                  </Badge>
                )}
                {selectedItem.Bullet5 && (
                  <Badge pill className="bg-dark-blue">
                    {selectedItem.Bullet5}
                  </Badge>
                )}
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default CaseStudiesPage;
